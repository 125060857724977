@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-gray-700 bg-white bg-gradient-to-br from-chitored-50 to-white via-slate-600;

    font-family: Roboto, sans-serif, system-ui;
  }
}

@layer components {
  .nav-bar-button {
    @apply px-2 py-1 duration-300 transform rounded-lg cursor-pointer active:scale-110 hover:text-chitored-50 max-w-max whitespace-nowrap;
  }
  .in-text-link {
    @apply font-bold duration-500 hover:text-chitored-50 text-facebookBlue-500;
  }
}

.sliderClass {
  @apply shadow-2xl rounded-lg w-full;
}
